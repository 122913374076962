/*eslint-disable */

import Vue from 'vue'
import axios from 'axios';
import root from '@/_helpers/roots';

let doctorFeedbackApiUrl = root.doctorFeedbackApiUrl.link;
let doctorsReviewListByIdApiUrl = root.doctorsReviewListByIdApiUrl.link;

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    // Get Doctor Feedback Action
    doctorsReviewListByIdAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(doctorsReviewListByIdApiUrl + payload.id + '/').then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Doctor Feedback Action
    getDoctorFeedbackListAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(doctorFeedbackApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Post Doctor Feedback Action
    doctorFeedbackPostAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(doctorFeedbackApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Delete Doctor Feedback Action
    deleteDoctorFeedbackByIdAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(doctorFeedbackApiUrl + payload.id + '/').then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Update Doctor Feedback Action
    updateDoctorFeedbackByIdAction(context, payload) {
        return new Promise((resolve, reject) => {
            axios.put(doctorFeedbackApiUrl + payload.data.id + '/', payload.data.data).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
  },
  getters: {
  }
}