/*eslint-disable */

import Vue from 'vue'
import axios from 'axios';
import root from '@/_helpers/roots';

let appointmentApiUrl = root.appointmentApiUrl.link;
let recentAppointmentApiUrl = root.recentAppointmentApiUrl.link;
let remarksApiUrl = root.remarksApiUrl.link;
let prescriptionListApiUrl = root.prescriptionListApiUrl.link;
let favouriteAppointmentListApiUrl = root.favouriteAppointmentListApiUrl.link;
let consentFormUpdateApiUrl = root.consentFormUpdateApiUrl.link;

export default {
  state: {
    appointmentListState:{},
    recentAppointmentListState:{},
    appointmentDetailState:{},
    appointmentListOfTherapyState:{}
  },
  mutations: {
    appointmentListMutation(state, value) {
      Vue.set(state, 'appointmentListState', value);
    },
    recentAppointmentListMutation(state, value) {
      Vue.set(state, 'recentAppointmentListState', value);
    },
    appointmentDetailMutation(state, value) {
      Vue.set(state, 'appointmentDetailState', value);
    },
    appointmentListOfTherapyMutation(state, value) {
      Vue.set(state, 'appointmentListOfTherapyState', value);
    },
  },
  actions: {
    // Post Consent Form Update Action Function
    consentFormUpdateAction(context, payload) {
        return new Promise((resolve, reject) => {
          axios.post(consentFormUpdateApiUrl, payload).then(response => {
            resolve(response);
          }).catch(err => {
            reject(err);
          })
        })
    },
    // Get Appointment List Action Function
    getAppointmentListOfTherapyApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(appointmentApiUrl + '?filter_type=psychiatrist', payload).then(response => {
            context.commit('appointmentListOfTherapyMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Favourite Appointment List Action Function
    getFavouriteAppointmentListApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(favouriteAppointmentListApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Recent Appointment List Action Function
    getRecentAppointmentListApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(recentAppointmentApiUrl, payload).then(response => {
            context.commit('recentAppointmentListMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Appointment List Action Function
    getAppointmentListApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(appointmentApiUrl, payload).then(response => {
            context.commit('appointmentListMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Appointment Detail By Id Function
    getAppointmentDetailByIdApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(appointmentApiUrl + payload.id + '/', payload).then(response => {
            context.commit('appointmentDetailMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Update Appointment Detail Action Function
    updateAppointmentDetailsApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.patch(appointmentApiUrl + payload.data.id + '/', payload.data.data).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Recent Appointment List Action Function
    getRemarksListApiFun(context, payload) {
        return new Promise((resolve, reject) => {
          axios.get(remarksApiUrl + '?appointment_id=' + payload.id).then(response => {
            resolve(response);
          }).catch(err => {
            reject(err);
          })
        })
    },
    // Get Recent Appointment List Action Function
    getPrescriptionListApiFun(context, payload) {
        return new Promise((resolve, reject) => {
          axios.get(prescriptionListApiUrl + '?appointment=' + payload.id).then(response => {
            resolve(response);
          }).catch(err => {
            reject(err);
          })
        })
    },
  },
  getters: {
    appointmentListGetters: state => state.appointmentListState,
    recentAppointmentListGetters: state => state.recentAppointmentListState,
    appointmentDetailGetetrs: state => state.appointmentDetailState,
    appointmentListOfTherapyGetters: state => state.appointmentListOfTherapyState,
  }
}